'use client';

import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/ui/Icon';
import { useOverlay } from '@/components/hooks/useOverlay';
import { usePrevious } from '@/components/hooks/usePrevious';
import Highlighter from '@/components/ui/Highlighter';
import { useKeypress } from '@/components/hooks/useKeyPress';
import Button from '@/components/ui/Button';
import { SearchResponse } from '@/apiTypes/types';
import { SEARCH_MIN_LENGTH } from '@/lib/constants';
import { loadAutocompleteSearch } from '@/lib/dataProviders/loadAutocompleteSearch';
import { SearchScope } from '@/lib/enums/searchScope';
import LoadingContent from '@/components/ui/LoadingContent';
import { getLocalizedPath } from '@/components/helpers/localize';
import { Locale } from '@/lib/enums/locale.enum';
import { getInternalLink } from '@/lib/helpers/getInternalLink';
import { afterOverlayClose } from '@/lib/helpers/overlays';
import useStartSearch from '@/lib/hooks/useStartSearch';
import styles from './styles.module.scss';

const Result: React.FunctionComponent<{
  highlight: boolean;
  keyword: string;
  label: string;
  url: string;
}> = (props) => (
  <li>
    <a
      href={props.url}
      className={classNames({
        [styles['highlight']]: props.highlight,
      })}
    >
      <Highlighter
        text={props.label}
        search={props.keyword}
      />
    </a>
  </li>
);

const SearchField: React.FunctionComponent<{
  isOverlay?: boolean;
}> = (props) => {
  const { isOverlayOpen, closeOverlay } = useOverlay('mobilesearch');
  const [hasFocus, setHasFocus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hasResultsFocus, setHasResultsFocus] = React.useState(false);
  const [form, setForm] = React.useState({
    keyword: '',
  });
  const [highlightIndex, setHighlightIndex] = React.useState(-1);
  const [results, setResults] = React.useState<SearchResponse>();
  const searchField = React.useRef<HTMLInputElement>(null);
  const searchResults = React.useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const language = i18n.language as Locale;
  const localizedSearch = getLocalizedPath('search', 'search', language);
  const startSearch = useStartSearch(localizedSearch);

  const search = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form.keyword !== '') {
      closeOverlay();
      afterOverlayClose(() => {
        startSearch({ query: form.keyword });
        setForm({
          keyword: '',
        });
      });
    }
  };

  const previousOverlayState = usePrevious(isOverlayOpen);

  React.useEffect(() => {
    if (props.isOverlay && !previousOverlayState && isOverlayOpen && searchField.current) {
      if (searchField && searchField.current) {
        searchField.current.focus();
        setTimeout(() => {
          document.documentElement.scrollTo({
            top: 1, // 0 does not work on ios on keyboard events
          });
        }, 151); // overlay animation duration + 1
      }
    }
  }, [isOverlayOpen, searchField, previousOverlayState, props.isOverlay]);

  useKeypress(
    'ArrowUp',
    () => {
      if (hasFocus) {
        setHighlightIndex(highlightIndex - 1);
      }
    },
    [hasFocus, highlightIndex, setHighlightIndex],
  );
  useKeypress(
    'ArrowDown',
    () => {
      if (hasFocus) {
        setHighlightIndex(highlightIndex + 1);
      }
    },
    [hasFocus, highlightIndex, setHighlightIndex],
  );
  useKeypress(
    'Escape',
    () => {
      if (hasFocus) {
        setForm({
          ...form,
          keyword: '',
        });
        setHighlightIndex(-1);
      }
    },
    [hasFocus, highlightIndex, setHighlightIndex],
  );

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target;
      if (target && searchResults.current && searchResults.current.contains(target as Node)) {
        setHasFocus(true);
        setHasResultsFocus(true);
        setTimeout(() => {
          if (searchField && searchField.current) {
            searchField.current.focus();
          }
        });
      } else {
        setHasResultsFocus(false);
      }
    };
    globalThis.addEventListener('mousedown', handleClickOutside);
    return () => {
      globalThis.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hasResultsFocus, searchResults]);

  const setSearchResult = async (value: string) => {
    setLoading(true);
    const searchResult = await loadAutocompleteSearch(value, SearchScope.all);
    setResults(searchResult);
    setLoading(false);
  };

  const showResults =
    form.keyword !== '' &&
    form.keyword.length >= SEARCH_MIN_LENGTH &&
    (hasFocus || hasResultsFocus) &&
    results &&
    (results.companies.items.length > 0 || results.persons.items.length > 0);

  // TODO - keyboard navigation based on index - single list but distributed to 2 columns.

  return (
    <div className={classNames(styles['search-field'], { [styles['is-overlay']]: props.isOverlay })}>
      <div className={styles['search-field__header']}>
        <form
          className={classNames(styles['search-field__form'], { [styles['focus']]: hasFocus })}
          onSubmit={search}
          autoComplete="off"
        >
          {loading ? (
            <LoadingContent
              size="small"
              className={styles['search-field__loader']}
            />
          ) : (
            <Icon
              width={24}
              height={24}
              kind="search"
              wrapperClassName={styles['search-field__icon']}
            />
          )}
          <input
            name="keyword"
            type="text"
            value={form.keyword}
            autoComplete="off"
            onChange={(e) => {
              setForm({
                ...form,
                keyword: e.target.value,
              });
              void setSearchResult(e.target.value);
            }}
            onFocus={() => {
              setHasFocus(true);
            }}
            onBlur={() => {
              setHasFocus(false);
            }}
            ref={searchField}
            placeholder={t('components.search.placeholder')}
            title={t('components.search.placeholder')}
          />
          <Button
            title={t('components.search.startSearch')}
            theme="primary"
            type="submit"
            loading={loading}
          />
        </form>
        <button
          className={styles['search-field__header__close']}
          onClick={closeOverlay}
          type="button"
        >
          <Icon
            width={24}
            height={24}
            kind="close"
          />
        </button>
      </div>
      {showResults && (
        <div
          className={styles['search-field__results']}
          onMouseDown={() => {
            setHasFocus(true);
            setHasResultsFocus(true);
          }}
          ref={searchResults}
        >
          {results && results.companies.items.length > 0 && (
            <div className={styles['search-field__results__group']}>
              <p>{t('components.search.companies')}</p>
              <ul className={styles['search-field__results__list']}>
                {results.companies.items.map((company, itemIndex) => (
                  <Result
                    key={company.id}
                    highlight={highlightIndex === itemIndex}
                    keyword={form.keyword}
                    label={company.name}
                    url={getInternalLink('Company', company.id, i18n.language as Locale, company.name)}
                  />
                ))}
              </ul>
            </div>
          )}
          {results && results.persons.items.length > 0 && (
            <div className={styles['search-field__results__group']}>
              <p>{t('components.search.persons')}</p>
              <ul className={styles['search-field__results__list']}>
                {results.persons.items.map((person, itemIndex) => (
                  <Result
                    key={person.id}
                    highlight={highlightIndex === itemIndex}
                    keyword={form.keyword}
                    label={person.name}
                    url={getInternalLink('Person', person.id, i18n.language as Locale, person.name)}
                  />
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SearchField.displayName = 'SearchField';

export default SearchField;
