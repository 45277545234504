'use client';

import { useRouter } from 'next/navigation';
import { SearchParams } from '@/components/search/SearchResults';

const useStartSearch = (basePath: string) => {
  const router = useRouter();

  return (values: SearchParams) => {
    const params = new URLSearchParams();
    Object.entries(values).forEach(([key, value]) => {
      if (!!value && (!Array.isArray(value) || value.length > 0)) {
        params.set(key, value.toString());
      }
    });
    router.push(`${basePath}?${params.toString()}`);
  };
};

export default useStartSearch;
